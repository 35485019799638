import { graphql, HeadProps, PageProps } from 'gatsby'
import React, { useEffect } from 'react'

import FeatureOverview from '../components/FeatureOverview'
import Hero from '../components/Hero'
import ImpactModule from '../components/Impact'
import Layout from '../components/Layout'
import ProgramCardGrid from '../components/ProgramCardGrid'
import RelatedResources from '../components/RelatedResources'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import Testimonial from '../components/UI/Testimonial'
import { useSplitTracking } from '../hooks/useSplitTracking'
import { useTrackSolutionPage } from '../utils/dataLayer'

const JobSpecificPage = ({
  data,
  pageContext,
}: PageProps<
  Queries.jobSpecificPageQuery,
  { filterType: string; slug: string }
>) => {
  const pageData = data?.contentstackPageJobSpecificPrograms
  const breadcrumbLinks = [{ text: pageData?.title || '' }]

  const parentPage = data?.parentUrl.nodes?.[0]

  if (parentPage?.title && parentPage?.pageUrl) {
    breadcrumbLinks.unshift({
      text: parentPage?.title,
      target: parentPage?.pageUrl,
    })
  }

  let resourcesByFilter
  switch (pageContext.filterType) {
    case 'Contentstack_topic_resource_type':
      resourcesByFilter = [
        ...(data?.resourceCardsByResourceTypeFilter?.nodes || []),
      ]
      break
    case 'Contentstack_topic_industry':
      resourcesByFilter = [
        ...(data?.resourceCardsByIndustryFilter?.nodes || []),
      ]
      break
    case 'Contentstack_topic_role':
      resourcesByFilter = [...(data?.resourceCardsByRoleFilter?.nodes || [])]
      break
    default:
      resourcesByFilter = []
      break
  }

  const allFeaturedResourceCards = data?.allFeaturedResourceCards?.nodes || []
  if (resourcesByFilter.length === 0) {
    allFeaturedResourceCards?.forEach((resource: any) =>
      resourcesByFilter.push(resource),
    )
  }

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  useTrackSolutionPage(pageContext.slug, data)

  const track = useSplitTracking()

  useEffect(() => {
    track('job_specific__pageview', undefined, { url: window.location.href })
  }, [])

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={pageContext.slug}
      title={metaTitle}
    >
      <Hero content={pageData?.page_header?.[0]} />
      {pageData?.introduction?.[0] ? (
        <FeatureOverview
          body={
            pageData?.introduction?.[0]?.overview_description ||
            pageData?.introduction?.[0]?.topic?.[0]?.overview_description
          }
          headline={
            pageData?.introduction?.[0]?.headline ||
            pageData?.introduction?.[0]?.topic?.[0]?.overview_headline
          }
        />
      ) : null}
      {pageData?.optional_sections?.map((section: any) => (
        <React.Fragment key={section.id}>
          {section?.internal?.type === 'Contentstack_section_impact_stats' && (
            <ImpactModule content={section} />
          )}
          {section?.internal?.type === 'Contentstack_section_testimonial' && (
            <Testimonial
              citation={section.testimonial?.[0]?.source}
              testimonial={section.testimonial?.[0]?.testimonial}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_program_card_set' && (
            <ProgramCardGrid
              headline={section?.headline || ''}
              programs={section?.sales_training_programs || []}
            />
          )}
        </React.Fragment>
      ))}
      {pageData?.resources?.[0] ? (
        <RelatedResources
          content={{
            related_resources: resourcesByFilter || [],
            ...pageData?.resources?.[0],
          }}
        />
      ) : null}
    </Layout>
  )
}

export const Head = ({ data }: HeadProps<Queries.jobSpecificPageQuery>) => (
  <>
    <SchemaMarkup
      data={data.contentstackPageJobSpecificPrograms?.schema_markup}
    />
    <SchemaMarkupBreadcrumb
      pageTitle={data?.contentstackPageJobSpecificPrograms?.title}
      parent={data?.parentUrl.nodes?.[0]}
    />
  </>
)

export default JobSpecificPage

export const data = graphql`
  query jobSpecificPage(
    $pageId: String
    $filterId: String
    $roleFilterId: String
    $locale: String
    $parentCategoryId: String
  ) {
    contentstackPageJobSpecificPrograms(id: { eq: $pageId }) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        ...PageHeaderSection
        topic {
          ... on Contentstack_topic_role {
            id
            headline
            subheading
            feature_image {
              description
              imgixImage {
                gatsbyImageData(width: 1280, imgixParams: { q: 65 })
              }
            }
          }
        }
      }
      introduction {
        ...FeatureOverviewSection
        topic {
          ... on Contentstack_topic_role {
            id
            overview_headline
            overview_description
          }
        }
      }
      program_list_title
      optional_sections {
        ... on Contentstack_section_impact_stats {
          ...ImpactStatsSection
        }
        ... on Contentstack_section_testimonial {
          ...TestimonialSection
        }
        ... on Contentstack_section_program_card_set {
          ...ProgramCardSetSection
        }
      }
      resources {
        ...RelatedResourcesSection
      }
    }

    resourceCardsByRoleFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { roles: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByResourceTypeFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { resource_type: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByIndustryFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { industry: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    allFeaturedResourceCards: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: { elemMatch: { is_featured: { eq: "Yes" } } }
      }
      sort: { fields: resource___publication_date, order: DESC }
      limit: 15
    ) {
      nodes {
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    parentUrl: allContentstackPageProductOverview(
      filter: {
        product_category: { elemMatch: { id: { eq: $parentCategoryId } } }
      }
    ) {
      nodes {
        title
        pageUrl: url
      }
    }

    allContentstackPageSalesTrainingProgram(
      filter: {
        publish_details: { locale: { eq: $locale } }
        program: {
          elemMatch: { roles: { elemMatch: { id: { eq: $roleFilterId } } } }
        }
      }
    ) {
      nodes {
        id
        title
        url
        program {
          headline
          short_description
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 374, imgixParams: { q: 60 })
            }
          }
        }
      }
    }
  }
`
