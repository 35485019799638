import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const ProgramCardSection = styled.section`
  padding: 72px 0 96px;

  .headline {
    margin-bottom: 64px;
    color: ${rspTheme.palette.primary.main};
  }

  .programGrid {
    gap: 48px;
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      width: 30%;
      min-width: 300px;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 64px 0 80px;

    .headline {
      font-size: 2.8rem;
      margin-bottom: 32px;
    }

    .programGrid {
      justify-content: center;
      gap: 32px;
    }
  }
`
