import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import FeatureCardVertical from '../UI/FeatureCardVertical'
import Wrapper from '../UI/Wrapper'
import { ProgramCardSection } from './styles'

interface ProgramCardGridProps {
  headline: string
  programs: any[]
}

const ProgramCardGrid = (props: ProgramCardGridProps) => {
  const { headline, programs = [] } = props

  return (
    <ProgramCardSection>
      <Wrapper>
        <Typography className="headline" component="h2" variant="h2">
          {headline}
        </Typography>
        <Grid
          className="programGrid"
          component="ul"
          container
          justifyContent="flex-start"
        >
          {programs?.map((program: any) => (
            <li key={program.id}>
              <FeatureCardVertical
                body={program.program?.[0]?.short_description}
                cardType=""
                featureImage={program.program?.[0]?.feature_image}
                headline={program.program?.[0]?.headline}
                linkTarget={program.url}
              />
            </li>
          ))}
        </Grid>
      </Wrapper>
    </ProgramCardSection>
  )
}

export default ProgramCardGrid
