import styled from 'styled-components'

import { rspTheme } from '../../../styles/rspTheme'

export const TestimonialContainer = styled.figure`
  background-color: ${rspTheme.palette.primary.background};
  padding: 96px 0;

  .quote {
    color: ${rspTheme.palette.primary.main};

    &::before {
      content: '“';
    }
    &::after {
      content: '”';
    }
  }

  .citation {
    color: ${rspTheme.palette.secondary.dark};
    margin-top: 24px;

    &::before {
      content: '- ';
    }
  }

  &.themeDark {
    background-color: ${rspTheme.palette.primary.dark};

    .quote {
      color: #ffffff;
    }

    .citation {
      color: ${rspTheme.palette.secondary.light};
    }
  }

  .quote,
  .citation {
    grid-column: 2 / -2;
  }

  @media (max-width: ${rspTheme.breakpoints.values.sm}px) {
    padding: 48px 5%;

    .quote,
    .citation {
      grid-column: 1 / -1;
      font-size: 2.6rem;
    }
  }
`
