import Typography from '@material-ui/core/Typography'
import React from 'react'

import GridContainer from '../GridContainer'
import { TestimonialContainer } from './styles'

interface TestimonialProps {
  testimonial: string
  citation: string
  theme?: 'Light' | 'Dark'
}

const Testimonial = (props: TestimonialProps) => {
  const { testimonial, citation, theme = 'Light' } = props

  return (
    <TestimonialContainer className={`theme${theme}`}>
      <GridContainer>
        <Typography className="quote" component="blockquote" variant="h2">
          {testimonial}
        </Typography>
        <Typography className="citation" component="figcaption" variant="h2">
          {citation}
        </Typography>
      </GridContainer>
    </TestimonialContainer>
  )
}

export default Testimonial
